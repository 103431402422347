// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-career-js": () => import("../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-code-js": () => import("../src/pages/code.js" /* webpackChunkName: "component---src-pages-code-js" */),
  "component---src-pages-contest-js": () => import("../src/pages/contest.js" /* webpackChunkName: "component---src-pages-contest-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

